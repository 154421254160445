import { useState, useEffect } from 'react'
import Modal from './modal'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.locale('ru')

const daysToShow = 5
const host = process.env.REACT_APP_HOST_API_URL
// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const modalData = {}

const Schedule = () => {

    const [slots, setSlots] = useState([])
    const [futherSlots, setFurtherSlots] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isCardLoading, setIsCardLoading] = useState(false)

    const [minDate, setMinDate] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState(dayjs())

    const [doctorUid, setDoctorUid] = useState(null)
    const [doctorData, setDoctorData] = useState({})

    useEffect(() => {
      const url = new URL(window.location.href)
      setDoctorUid(url.pathname.split('/').pop())
    }, [])

    useEffect(() => {
        if (doctorUid) {
            fetch(`${host}/api/doctors/${doctorUid}`).then(r => r.json()).then(d => setDoctorData(d))
        }
    }, [doctorUid])

    const monthsToShow = (date) => {
        const currDate = dayjs(date)
        const nextDate = currDate.add(daysToShow-1, 'day')

        if (currDate.month() !== nextDate.month()) {
            return `${currDate.format('MMMM')} - ${nextDate.format('MMMM')}`
        } else {
            return currDate.format('MMMM')
        }

    }

    useEffect(() => {
        if (Object.keys(doctorData).length > 0) {
            fetch(`${host}/api/slots/patients/${doctorUid}/${selectedDate.unix()}/`)
                .then((res) => res.json())
                .then((data) => {
                    const slots = data.today.map((slot) => parseInt(slot) * 1000).sort()
                    setSlots(slots)
                    if (data.further) {
                        setFurtherSlots(data.further.map((slot) => parseInt(slot) * 1000))
                    }
                })
        }
    }, [selectedDate, doctorData])

    useEffect(() => {
        if (doctorUid != null || doctorUid != '') {
            setSelectedDate(minDate)
        }
    }, [minDate])

    if (Object.keys(doctorData).length <= 0) {
        return <div>Загрузка...</div>
    }

    return <>
        <Modal 
            data={modalData} 
            isOpen={isModalOpen} 
            onClose={() => setIsModalOpen(false)}
        />
        <div className="flex items-center justify-center bg-gray-100 _h-full">
            <div className="bg-white p-3 m-3 rounded shadow flex flex-grow">
                <div className="flex-grow h-screen _py-5">
                    <div className="mb-2 mx-2 text-center text-xl font-medium">Запись к специалисту: <br /> {doctorData.firstName} {doctorData.lastName}</div>
                    <div className="mb-5 mx-2 text-center text-xl font-medium">
                        Стоимость консультации: <br /> 
                        {doctorData.primary_price} рублей {doctorData.secondary_price && "(первичная)"}
                        {doctorData.secondary_price && <br />}
                        {doctorData.secondary_price && doctorData.secondary_price + " рублей (повторная)"}
                    </div>
                    <div><hr style={{
                            marginTop: '-8px',
                            marginBottom: '12px'
                    }}/></div>
                    <div className="flex justify-between items-center px-2 mb-5">
                    <div className="mb-2 mt-0 text-xl font-medium leading-tight">{monthsToShow(minDate)}</div>
                        <div>
                            <button 
                                className="rounded bg-neutral-100 px-3 pb-2 pt-2.5 text-xl ml-2" 
                                onClick={() => {
                                    if (minDate.subtract(1, 'day') < dayjs()) {
                                        return
                                    }
                                    setMinDate(minDate.subtract(daysToShow, 'day'))
                                }}
                            >
                                &#x2190;
                            </button>
                            <button 
                                className="rounded bg-neutral-100 px-3 pb-2 pt-2.5 text-xl ml-2" 
                                onClick={() => setMinDate(minDate.add(daysToShow, 'day'))}
                            >
                                &#8594;
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-5">
                        {Array.from({length: daysToShow}, (_, index) => minDate.add(index, 'day')).map((date) => <>
                            <div 
                                key={date} 
                                className={
                                    `${selectedDate.day() == date.day() ? 'bg-neutral-300' : 'bg-neutral-100'} hover:bg-gray-300 cursor-pointer rounded px-4 py-2 mx-2 text-center`
                                }
                                onClick={() => setSelectedDate(date)}
                            >
                                <div>
                                    {date.format('dd')}
                                </div>
                                <div className="font-medium">
                                    {date.format('DD')}
                                </div>
                            </div>
                        </>)}
                    </div>  
                    {/* {slots.length > 0 && <div className="px-2 my-5 text-center">Время указано в часовом поясе: {timezone}</div>} */}
                    {slots.length > 0 && <div className="px-2 my-5 text-center">Время в московсом часовом поясе</div>}
                    <div className="">
                        {slots.map((slot) => <div key={slot} className="px-5 my-5">
                            <button
                                type="button"
                                disabled={isCardLoading}
                                onClick={() => {
                                    modalData.doctorUid = doctorUid
                                    modalData.slot = dayjs(slot)
                                    modalData.doctorData = doctorData
                                    setIsModalOpen(true)
                                    setIsCardLoading(false)
                                }}
                                className="h-12 w-full rounded bg-neutral-100 px-6 pb-2 pt-2.5 font-medium _text-primary-700 hover:bg-primary-accent-100"
                            >
                                {dayjs(Number(slot)).format('HH:mm')}
                            </button>
                        </div>)}
                        {slots.length < 1 && <div className="text-center m-7">На эту дату нет записи</div>}
                        {(slots.length < 1 && futherSlots.length > 0) && <div className="text-center m-7">
                            <div>Ближайшие даты для записи:</div>
                            {futherSlots.map((slot) => <div className="mt-5">
                                <button
                                    type="button"
                                    onClick={() => {
                                            setSelectedDate(dayjs(Number(slot)))
                                            setMinDate(dayjs(Number(slot)))
                                        }
                                    }
                                    className="h-12 w-full rounded bg-neutral-100 px-6 pb-2 pt-2.5 font-medium _text-primary-700 hover:bg-primary-accent-100"
                                >
                                    {dayjs(Number(slot)).format('DD MMM')}
                                </button>                                
                            </div>)}
                        </div>}
                    </div>
                </div>
            </div>
            
        </div>
    </>

}

export default Schedule
