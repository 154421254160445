import { useState } from 'react'
import InputMask from 'react-input-mask'

import Loader from '../loader'

const host = process.env.REACT_APP_HOST_API_URL
const numberPattern = /\d+/g


const getPhoneNumber = (inputValue) => {
    const regexpResult = inputValue.match(numberPattern)
    if (regexpResult !== null) {
        return regexpResult.join('')
    }
    return ''
}

const Modal = ({isOpen, onClose, data}) => {

    const [isDisabled, setIsDisabled] = useState(false)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
  
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const [showOk, setShowOk] = useState(false);
    const [isOkDisabled, setIsOkDisabled] = useState(false);

    if (!isOpen) {
        return null;
    }

    const handleSubmit = () => {
        setFirstNameError(!firstName)
        setLastNameError(!lastName)

        let isPhoneValid = getPhoneNumber(phoneNumber).length === 11
        setPhoneNumberError(!isPhoneValid)

        if (firstName && lastName && isPhoneValid) {
            setIsDisabled(true)
            submitVisit()
        }
      }

    const inputCss = 'w-full p-3 mb-2 rounded border border-solid border-neutral-400 bg-transparent text-neutral-700 outline-none'

    const submitVisit = () => {
        const timestamp = data.slot.unix()
        const body = {
            doctor: data.doctorUid,
            timestamp: timestamp,
            patient: {
                'phone': getPhoneNumber(phoneNumber),
                'firstName': firstName,
                'lastName': lastName
            },
        }
        fetch(`${host}/api/visits/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then((res) => res.json())
        .then((data) => setShowOk(true)) 
    }

    return <>
        <div className="fixed inset-0 z-50 overflow-auto bg-opacity-50 bg-black">
            <div className="flex items-center justify-center min-h-screen">
                <div className={`${!showOk ? 'hidden' : ''} bg-white rounded-lg p-6 m-4 max-w-xl`}>
                    <div className="text-center text-xl">
                        Запись подтверждена
                    </div>
                    <div className="text-center mt-3">
                        Вы успешно записаны на прием к {data.doctorData.firstName} {data.doctorData.lastName} на {data.slot.format('DD.MM.YYYY HH:mm')}
                    </div>
                    <div className="text-center mt-3">
                        Специалист свяжется с вами по указанному номеру телефона
                    </div>
                    <button 
                        disabled={isOkDisabled}
                        onClick={() => {
                                setIsOkDisabled(true)
                                window.location.reload()
                            }
                        }
                        className="bg-blue-100 mt-10 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        {isOkDisabled ? <Loader /> : 'Супер!'}
                    </button>  
                </div>
                <div className={`${showOk ? 'hidden' : ''} bg-white rounded-lg p-6 m-4 max-w-xl`}>
                    <div className="text-center text-xl">
                        Укажите ваши данные:
                    </div>
                    <div className="_p-4 mt-5">
                        <input
                            type="text"
                            placeholder="Ваше имя"
                            className={`${inputCss} ${firstNameError ? 'border-red-500' : 'border-gray-300'}`}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Ваша фамилия"
                            className={`${inputCss} ${lastNameError ? 'border-red-500' : 'border-gray-300'}`}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <InputMask
                            mask='+7 (999) 999-99-99' 
                            placeholder="Ваш телефон"
                            className={`${inputCss} ${phoneNumberError ? 'border-red-500' : 'border-gray-300'}`}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="text-center">Мы отправим детали записи на этот номер телефона</div>
                    <button 
                        disabled={isDisabled}
                        onClick={handleSubmit}
                        className="bg-blue-100 mt-10 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        {isDisabled ? <Loader /> : 'Записаться'}
                    </button>  
                    <button 
                        disabled={isDisabled}
                        onClick={() => {
                                setFirstNameError(false)
                                setLastNameError(false)
                                setPhoneNumberError(false)
                        
                                onClose()
                            }
                        }
                        className="bg-gray-100 mt-3 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        Отмена
                    </button>                      
                </div>
            </div>
        </div>
    </>

}

export default Modal
